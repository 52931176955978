import { FormattedMessage } from 'react-intl';

import { executeAndTrack, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';

type ServiceInfoDialogMenuItemProps = {
    chapter: string;
    activeChapter: string;
    onClick: React.MouseEventHandler<HTMLElement>;
    trackingLabel: string;
};

export const ServiceInfoDialogMenuItem = ({
    chapter,
    activeChapter,
    onClick,
    trackingLabel,
}: ServiceInfoDialogMenuItemProps) => (
    <span
        className={activeChapter === chapter ? 'active' : undefined}
        onClick={executeAndTrack(onClick, {
            category: TRACKING_CATEGORIES.SERVICE_INFO,
            action: 'Chapter clicked',
            label: trackingLabel,
        })}
        data-name={chapter}
    >
        <FormattedMessage id={`intl-msg:network.serviceInfo.${chapter}`} />
    </span>
);
