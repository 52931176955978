import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';

import type { ConnectionType } from '../services/partnerNetwork.types';

type ConnectionTypeFormatterProps = {
    connectionType: ConnectionType;
    showIcon?: boolean;
    showName?: boolean;
    count?: number;
    classNames?: string;
};

const ConnectionTypeFormatter = (props: ConnectionTypeFormatterProps) => {
    const { connectionType, showIcon = false, showName = true, count, classNames} = props;

    const icons = {
        'order-exchange': 'rioglyph rioglyph-tms',
        'rental': 'rioglyph rioglyph-delivery-completed',
    };

    const name = connectionType?.split(/\W+/).map((str) => str.charAt(0).toUpperCase() + str.slice(1)).join(' ');

    return (
        <SimpleTooltip placement='bottom' content={name}>
            <span className={`${classNames} text-color-darker`}>
                {count && <span className='padding-right-5'>{count}</span> }
                {showIcon && <span className={icons[connectionType]} />}
                {showIcon && showName && <span className='padding-right-5' />}
                {showName && name}
            </span>
        </SimpleTooltip>
    );
};

export default ConnectionTypeFormatter;
