import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import isNil from 'lodash/fp/isNil';
import isEmpty from 'lodash/fp/isEmpty';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Button from '@rio-cloud/rio-uikit/Button';

import { getTrackingAttributes, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';
import type { Partner } from '../../services/partnerNetwork.types';
import useEditPartner from './useEditPartner';

type EditPartnerDialogProps = {
    partner: Partner;
    onClose: () => void;
    show: boolean;
};

export type FormEditPartner = {
    name: string;
    description: string | undefined;
};

const EditPartnerDialog = (props: EditPartnerDialogProps) => {
    const { partner, onClose, show } = props;
    const intl = useIntl();

    const { handleEditPartner } = useEditPartner();

    const { register, handleSubmit } = useForm<FormEditPartner>({
        mode: 'onSubmit',
        defaultValues: {
            name: partner.name,
            description: partner.description,
        },
    });

    const hasError = !isNil(partner.name) && isEmpty(partner.name);

    const onSubmit = async (formEditPartner: FormEditPartner) => {
        await handleEditPartner(partner, formEditPartner);
        onClose();
    };

    const handleClose = () => {
        onClose();
    };

    const title = <FormattedMessage id='intl-msg:network.editParter.title' />;

    const body = (
        <div
            className='display-flex align-items-start'
            {...getTrackingAttributes({
                trigger: 'visibility',
                category: TRACKING_CATEGORIES.OVERVIEW,
                action: 'Edit partner dialog shown',
                label: 'Edit partner dialog shown',
            })}
        >
            <div
                className={`margin-right-25 bg-lightest rounded-circle padding-25 
                display-grid place-items-center text-size-16`}
            >
                <span className='rioglyph rioglyph-user text-color-dark text-size-200pct' />
            </div>
            <form className={`flex-1-1 form-group margin-bottom-15 ${hasError ? 'has-feedback has-error' : ''}`}>
                <label className='control-label' htmlFor='input'>
                    <FormattedMessage id='intl-msg:network.label.partnerName' />
                </label>
                <input
                    type='text'
                    className='form-control'
                    {...register('name', {
                        required: intl.formatMessage({ id: 'intl-msg:network.addPartner.nameInput.validation.empty' }),
                    })}
                    // defaultValue={partner.name}
                />
                <label className='control-label margin-top-10' htmlFor='partnerDescription'>
                    <FormattedMessage id='intl-msg:network.addPartner.description' />
                </label>
                <textarea className='form-control' rows={3} id='partnerDescription' {...register('description')} />
                {hasError && (
                    <span className='help-block'>
                        <FormattedMessage id='intl-msg:network.addPartner.nameInput.validation.empty' />
                    </span>
                )}
            </form>
        </div>
    );

    const footer = (
        <div className='btn-toolbar justify-content-end'>
            <Button onClick={handleClose}>
                <FormattedMessage id='intl-msg:network.label.cancel' />
            </Button>
            <Button
                bsStyle={'primary'}
                className='btn-icon-right'
                disabled={isEmpty(partner?.name)}
                onClick={handleSubmit(onSubmit)}
            >
                <span className='rioglyph rioglyph-chevron-right' />
                <FormattedMessage id='intl-msg:network.label.editPartner' />
            </Button>
        </div>
    );

    return (
        <Dialog
            show={show}
            title={title}
            footer={footer}
            body={body}
            bsSize={Dialog.SIZE_SM}
            onClose={handleClose}
            showCloseButton
        />
    );
};

export default EditPartnerDialog;
