import type { ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import RadioButton from '@rio-cloud/rio-uikit/RadioButton';
import FormLabel from '@rio-cloud/rio-uikit/FormLabel';


import { getTrackingAttributes, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';
import { ConnectionTypes } from '../../services/partnerNetwork.types';
import ConnectionTypeFormatter from '../../components/ConnectionTypeFormatter';

type CreateStepOneProps = {
    partnerName: string | undefined;
    onChangePartnerName: (value: string) => void;
    partnerDescription: string | undefined;
    onChangePartnerDescription: (event: ChangeEvent<HTMLTextAreaElement>) => void;
};

const CreateStepOne = (props: CreateStepOneProps) => {
    const {
        partnerName,
        onChangePartnerName,
        partnerDescription,
        onChangePartnerDescription,
    } = props;

    const intl = useIntl();
    const hasNoNameError = !isNil(partnerName) && isEmpty(partnerName);

    return (
        <div
            className='display-flex align-items-start'
            {...getTrackingAttributes({
                trigger: 'visibility',
                category: TRACKING_CATEGORIES.OVERVIEW,
                action: 'Add partner dialog step one shown',
                label: 'Add partner dialog step one shown',
            })}
        >
            <div
                className={`margin-right-25 bg-lightest rounded-circle padding-25 
                display-grid place-items-center text-size-16`}
            >
                <span className='rioglyph rioglyph-user text-color-dark text-size-200pct' />
            </div>
            <div className={`flex-1-1 form-group margin-bottom-15 ${hasNoNameError? 'has-feedback has-error' : ''}`}>
                <label className='control-label' htmlFor='input'>
                    <FormattedMessage id='intl-msg:network.label.connectionType' />
                </label>
                <div>
                    <div>
                        <RadioButton disabled name='radios' id='connectionTypeOrderExchange'
                                     value={ConnectionTypes.ORDER_EXCHANGE} defaultChecked>
                            <ConnectionTypeFormatter connectionType={ConnectionTypes.ORDER_EXCHANGE} />
                        </RadioButton>
                    </div>
                    <div>
                        <RadioButton disabled name='radios' id='connectionTypeRental' value={ConnectionTypes.RENTAL}>
                            <ConnectionTypeFormatter connectionType={ConnectionTypes.RENTAL} />
                        </RadioButton>
                    </div>
                </div>
                <FormLabel className='control-label margin-top-10' htmlFor='input'>
                    <FormattedMessage id='intl-msg:network.label.partnerName' />
                </FormLabel>
                <ClearableInput
                    value={partnerName}
                    placeholder={intl.formatMessage({
                        id: 'intl-msg:network.addPartner.nameInput.placeholder',
                    })}
                    onChange={onChangePartnerName}
                />
                <FormLabel className='control-label margin-top-10' htmlFor='partnerDescription'>
                    <FormattedMessage id='intl-msg:network.addPartner.description' />
                </FormLabel>
                <textarea
                    className='form-control'
                    rows={3}
                    id='partnerDescription'
                    value={partnerDescription}
                    onChange={onChangePartnerDescription}
                />
                {hasNoNameError && (
                    <span className='help-block'>
                        <FormattedMessage id='intl-msg:network.addPartner.nameInput.validation.empty' />
                    </span>
                )}
            </div>
        </div>
    );
};

export default CreateStepOne;
