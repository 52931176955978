import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../config';
import {
    mapAccountProfile,
    mapEmailInvitationToApi,
    mapInvitation,
    mapInvitationRedirectToApi,
    mapInvitationToApi,
    mapPartnerConnectionTypeList,
    mapPartnerList,
    mapPartnerToApi,
} from './partnerNetworkMapper';
import type {
    AccountProfile,
    ApiAccountProfile,
    ApiInvitation,
    ApiPartnerConnectionTypeList,
    ApiPartnerList,
    EmailInvitation,
    Invitation,
    InvitationQuery,
    InvitationRequest,
    Partner,
    PartnerConnectionTypeList,
    PartnerList,
} from './partnerNetwork.types';
import { prepareHeaders, handleResponse } from './api.helper';

export const partnerNetworkApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.PARTNER_NETWORK_SERVICE,
        prepareHeaders,
    }),
    tagTypes: ['Partners', 'PartnerConnectionTypes'],
    endpoints: builder => ({
        fetchPartners: builder.query<PartnerList, void>({
            query: () => ({
                url: '/partners',
                responseHandler: async (response: Response) => handleResponse(response),
            }),
            transformResponse: (response: ApiPartnerList) => mapPartnerList(response),
            providesTags: ['Partners'],
        }),
        storePartner: builder.mutation<void, Partner>({
            query: partner => ({
                url: `/partners/${partner.id}`,
                method: 'PUT',
                body: mapPartnerToApi(partner),
            }),
            invalidatesTags: ['Partners'],
        }),
        deletePartner: builder.mutation<void, Partner>({
            query: partner => ({
                url: `/partners/${partner.id}`,
                method: 'DELETE',
            }),
        }),
        fetchInvitation: builder.query<Invitation, InvitationQuery>({
            query: invitationQuery => ({
                url: `/partners/${invitationQuery.partnerId}/invitations/${invitationQuery.id}`,
                responseHandler: async (response: Response) => handleResponse(response),
            }),
            transformResponse: (response: ApiInvitation) => mapInvitation(response),
        }),
        createInvitation: builder.mutation<Invitation | undefined, InvitationRequest>({
            query: ({ partnerId, invitationRedirectUri }) => ({
                url: `/partners/${partnerId}/invitations`,
                method: 'POST',
                body: mapInvitationRedirectToApi({ redirectUri: invitationRedirectUri }),
                responseHandler: async (response: Response) => handleResponse(response),
            }),
            transformResponse: (response: ApiInvitation) => mapInvitation(response),
        }),
        updateInvitation: builder.mutation<void, Invitation>({
            query: invitation => ({
                url: `/partners/${invitation.partnerId}/invitations/${invitation.id}`,
                method: 'PUT',
                body: mapInvitationToApi(invitation),
            }),
            invalidatesTags: ['Partners'],
        }),
        createEmailInvitation: builder.mutation<Invitation | undefined, EmailInvitation>({
            query: (emailInvitation: EmailInvitation) => ({
                url: `/partners/${emailInvitation.partnerId}/invitations/${emailInvitation.invitationId}/emails`,
                method: 'POST',
                body: mapEmailInvitationToApi(emailInvitation),
                responseHandler: async (response: Response) => handleResponse(response),
            }),
            transformResponse: (response: ApiInvitation) => mapInvitation(response),
        }),
        fetchAccountProfile: builder.query<AccountProfile, string | undefined>({
            query: accountId => ({
                url: `/profiles/${accountId}`,
                responseHandler: async (response: Response) => handleResponse(response),
            }),
            transformResponse: (response: ApiAccountProfile) => mapAccountProfile(response),
        }),
        fetchPartnerConnectionTypes: builder.query<PartnerConnectionTypeList, void>({
            query: () => ({
                url: '/partner-connection-types',
                responseHandler: async (response: Response) => handleResponse(response),
            }),
            transformResponse: (response: ApiPartnerConnectionTypeList) => mapPartnerConnectionTypeList(response),
            providesTags: ['PartnerConnectionTypes'],
        }),
    }),
    refetchOnFocus: true,
});

export const {
    useFetchPartnersQuery,
    useStorePartnerMutation,
    useCreateInvitationMutation,
    useDeletePartnerMutation,
    useFetchInvitationQuery,
    useUpdateInvitationMutation,
    useCreateEmailInvitationMutation,
    useFetchAccountProfileQuery,
    useFetchPartnerConnectionTypesQuery,
} = partnerNetworkApi;
