import { FormattedMessage } from 'react-intl';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';

import { useAppDispatch } from '../../configuration/setup/hooks';
import { serviceInfoChapter, SERVICE_INFO_CHAPTER_WELCOME, showServiceInfoDialog } from '../../layout/appSlice';

const serviceInfoVersion = 'v0.1.0';

const ServiceInfo = () => {
    const dispatch = useAppDispatch();

    const showOnboardingDialog = () => {
        dispatch(serviceInfoChapter(SERVICE_INFO_CHAPTER_WELCOME));
        dispatch(showServiceInfoDialog(true));
    };

    const title = (
        <div>
            <FormattedMessage id='intl-msg:network.moduleName' />
            <span className={'text-color-gray margin-left-10'}>{serviceInfoVersion}</span>
        </div>
    );

    return (
        <ActionBarItem id={'serviceInfo'}>
            <ActionBarItem.Icon>
                <span className={'icon rioglyph rioglyph-info-sign'} />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover title={title}>
                <ActionBarItem.List>
                    <ActionBarItem.ListItem icon='rioglyph-hand-right' onClick={showOnboardingDialog}>
                        <FormattedMessage id='intl-msg:network.serviceInfo.welcome' />
                    </ActionBarItem.ListItem>
                </ActionBarItem.List>
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};

export default ServiceInfo;
