import { FormattedMessage } from 'react-intl';

import type { Partner } from '../../services/partnerNetwork.types';
import { getTrackingAttributes, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';
import { getInvitationDialogRedirectUri, showInvitationDialog } from '../../layout/appSlice';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';

type InvitationDetailsProps = {
    partner: Partner;
};

const InvitationDetails = ({ partner }: InvitationDetailsProps) => {
    const dispatch = useAppDispatch();

    const handleInviteAgainButtonClick = () => {
        dispatch(showInvitationDialog({ showDialog: true, partner }));
    };

    const invitationDialogRedirectUri = useAppSelector(getInvitationDialogRedirectUri);


    return (
        <div>
            <div className={'margin-bottom-15'}>
                <label>
                    <FormattedMessage id='intl-msg:network.label.partnerId' />
                </label>
                <div>{partner?.id}</div>
            </div>
            {partner.status === 'pending' && (
                <>
                    <div className={'margin-bottom-15'}>
                        <label>
                            <FormattedMessage id='intl-msg:network.label.invitation' />
                        </label>
                        <div>
                            <FormattedMessage id='intl-msg:network.invitationLink.resendinvitationPhrase' />
                        </div>
                    </div>
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={handleInviteAgainButtonClick}
                        {...getTrackingAttributes({
                            trigger: 'click',
                            category: TRACKING_CATEGORIES.INVITATION,
                            action: 'Create invitation link clicked',
                            label: 'Create invitation link clicked',
                        })}
                    >
                        <FormattedMessage id='intl-msg:network.label.resendInvitation' />
                    </button>
                </>
            )}
        </div>
    );
};

export default InvitationDetails;
