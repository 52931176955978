import TableSearch from '@rio-cloud/rio-uikit/TableSearch';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';

import type { Partner } from '../../services/partnerNetwork.types';
import HelpButton from '../helpButton/HelpButton';
import PartnerTable from '../table/PartnerTable';

const partners: Partner[] = [
    {
        id: '1',
        name: 'Happy Path Logistics Ltd.',
        status: 'connected',
    },
    {
        id: '2',
        name: 'Happy Sails Company',
        status: 'connected',
    },
    {
        id: '3',
        name: 'Mere Construction',
        status: 'expired',
    },
    {
        id: '4',
        name: 'Klotz Baustoffe GmbH',
        status: 'pending',
    },
];

const ScreenshotOfPartners = () => {
    const intl = useIntl();

    return (
        <div className='pointer-events-none user-select-none padding-15 bg-lighter'>
            <TableToolbar>
                <div className='table-toolbar-container'>
                    <div className='table-toolbar-group-left'>
                        <div className='table-toolbar-column'>
                            <div className='btn-toolbar table-btn-toolbar'>
                                <button className='btn btn-primary' role='button'>
                                    <span className='rioglyph rioglyph-plus' />
                                    <FormattedMessage id='intl-msg:network.label.addPartner' />
                                </button>
                                <HelpButton />
                            </div>
                        </div>
                    </div>
                    <div className='table-toolbar-group-right'>
                        <div className='table-toolbar-column justify-content-center'>
                            <div className='label label-muted label-filled'>
                                <FormattedPlural
                                    one={
                                        <FormattedMessage
                                            id='intl-msg:network.partnersTable.numPartners.one'
                                            values={{ count: partners.length }}
                                        />
                                    }
                                    other={
                                        <FormattedMessage
                                            id='intl-msg:network.partnersTable.numPartners.other'
                                            values={{ count: partners.length }}
                                        />
                                    }
                                    zero={<FormattedMessage id='intl-msg:network.partnersTable.numPartners.zero' />}
                                    value={partners.length}
                                />
                            </div>
                        </div>
                        <div className='table-toolbar-column'>
                            <TableSearch
                                placeholder={intl.formatMessage({
                                    id: 'intl-msg:network.partnersTable.search.placeholder',
                                })}
                            />
                        </div>
                    </div>
                </div>
            </TableToolbar>
            <PartnerTable partners={partners} isEmptyList={false} isNotFoundList={false} />
        </div>
    );
};

export default ScreenshotOfPartners;
