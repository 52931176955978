import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import BottomSheet from '@rio-cloud/rio-uikit/BottomSheet';

import { isUserSessionExpired } from './../configuration/login/loginSlice';
import { useAppDispatch, useAppSelector } from './../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from './../configuration/lang/langSlice';
import RouteUpdater from '../routes/RouteUpdater';
import { getSessionExpiredAcknowledged, hideSessionExpiredDialog } from './appSlice';
import AppHeader from '../features/header/AppHeader';
import Overview from '../pages/Overview';
import PartnerSidebar from '../features/sidebar/PartnerSidebar';

const content = (
    <>
        <div className='text-medium padding-bottom-15'>
            <FormattedMessage id='intl-msg:network.banner.beta' />
        </div>
        <FormattedMessage id='intl-msg:network.banner.message' />
    </>
);

const OverviewApp = () => {
    const dispatch = useAppDispatch();

    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const sessionExpiredAcknowledged = useAppSelector(getSessionExpiredAcknowledged);
    const [showBottomSheet, setShowBottomSheet] = useState(true);

    if (!(displayMessages && userLocale)) {
        return null;
    }

    const handleSessionExpiredDialogClose = () => dispatch(hideSessionExpiredDialog);
    const showSessionExpired = isSessionExpired && !sessionExpiredAcknowledged;

    return (
        <>
            <ApplicationLayout.Header>
                <AppHeader />
            </ApplicationLayout.Header>
            <ApplicationLayout.Sidebar className='right'>
                <PartnerSidebar />
            </ApplicationLayout.Sidebar>
            <ApplicationLayout.Body>
                <NotificationsContainer />
                <SessionExpiredDialog
                    locale={userLocale}
                    onClose={handleSessionExpiredDialogClose}
                    show={showSessionExpired}
                />
                <Overview />
                <RouteUpdater />
                <BottomSheet
                    show={showBottomSheet}
                    width={400}
                    onClose={() => setShowBottomSheet(false)}
                    detach
                    bodyClassName='padding-25 margin-right-25'
                >
                    {content}
                </BottomSheet>
            </ApplicationLayout.Body>
        </>
    );
};

export default OverviewApp;
