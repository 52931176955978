import { FormattedMessage, useIntl } from 'react-intl';
import type { UseFormReturn } from 'react-hook-form';

import { getTrackingAttributes, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';
import { useAppSelector } from '../../configuration/setup/hooks';
import type { FormEmailData } from './CreatePartnerDialog';
import { useFetchAccountProfileQuery } from '../../services/partnerNetworkApi';
import { getUserProfile } from '../../configuration/login/loginSlice';

type CreateStepTwoProps = {
    form: UseFormReturn<FormEmailData>;
};

const getGermanEmailContent = (requesterName = '', inviteeName = '') => (
    <>
        Hallo{inviteeName !== '' ? ` ${inviteeName}` : ''},<br />
        <br />
        Sie haben eine neue Anfrage für eine Partnerverbindung{requesterName !== ''
            ? ` von "${requesterName}"`
            : ''}{' '}
        erhalten.
        <br />
        <br />
        Die Annahme der Partnerverbindung ermöglicht Ihnen:
        <ul>
            <li>Transportaufträge digital zu erhalten</li>
            <li>
                Das Fahrzeugkennzeichen sowie die Fahrzeugposition für den Zeitraum des Transportauftrags freizugeben
                und an Ihren Auftraggeber zu übermitteln
            </li>
            <li>Einen sicheren und datenschutzkonformen Datenaustausch</li>
        </ul>
        <button type='button' className='btn btn-default btn-link btn-xs'>
            Verbindungsanfrage einsehen
        </button>
        <br />
        <br />
        Um die Anfrage einsehen und annehmen zu können, benötigen Sie einen RIO-Account.
        <br />
        Haben Sie noch keinen?{' '}
        <button type='button' className='btn btn-default btn-link btn-xs'>
            Registrieren Sie sich jetzt kostenlos.
        </button>
    </>
);

const getDefaultEmailContent = (requesterName = '', inviteeName = '') => (
    <>
        Hello{inviteeName !== '' ? ` ${inviteeName}` : ''},<br />
        <br />
        You have received a new request for a partner connection
        {requesterName !== '' ? ` from "${requesterName}"` : ''}.
        <br />
        <br />
        Accepting the partner connection allows the following:
        <ul>
            <li>Receiving transport orders digitally</li>
            <li>
                Share vehicle license plates as well as vehicle position for the period of a transport order and
                transmit it to your client
            </li>
            <li>A secure and data protection compliant data exchange</li>
        </ul>
        <button type='button' className='btn btn-default btn-link btn-xs'>
            View connection request
        </button>
        <br />
        <br />
        To view and accept the request, you need a RIO account.
        <br />
        Do you don't have one yet?{' '}
        <button type='button' className='btn btn-default btn-link btn-xs'>
            Register now for free.
        </button>
    </>
);

const getEmailContent = (requesterName = '', inviteeName = '', locale = 'de-DE') => {
    const language = new Intl.Locale(locale).language;
    const template = language === 'de' ? getGermanEmailContent : getDefaultEmailContent;
    return template(requesterName, inviteeName);
};

const isValidEmail = (email: string): boolean =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );

const CreateStepTwo = (props: CreateStepTwoProps) => {
    const { form } = props;
    const { formState, register, watch, getFieldState } = form;

    const intl = useIntl();

    const accountId = useAppSelector(getUserProfile)?.account;
    const locale = useAppSelector(getUserProfile)?.locale;

    const { data: accountProfile } = useFetchAccountProfileQuery(accountId, {
        skip: !accountId,
    });

    const name = watch('name');
    const { error: emailAddressError } = getFieldState('emailAddress', formState);

    const emailContent = getEmailContent(accountProfile?.displayName, name, locale);

    return (
        <div
            className='position-relative width-100pct'
            {...getTrackingAttributes({
                trigger: 'visibility',
                category: TRACKING_CATEGORIES.OVERVIEW,
                action: 'Add partner dialog step two shown',
                label: 'Add partner dialog step two shown',
            })}
        >
            <div className='padding-bottom-20 text-color-darker'>
                <FormattedMessage id='intl-msg:network.invitationLink.validityPhrase' />
            </div>
            <form>
                <div className={`form-group ${emailAddressError ? 'has-feedback has-error' : ''}`}>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:network.invitationLink.inviteeEmailAddress' />*
                    </label>
                    <div className='input-group'>
                        <span className='input-group-addon'>
                            <span className='rioglyph rioglyph-envelope' />
                        </span>
                        <input
                            type='text'
                            className='form-control'
                            placeholder={intl.formatMessage({
                                id: 'intl-msg:network.placeholder.email',
                            })}
                            {...register('emailAddress', {
                                required: true,
                                validate: isValidEmail,
                            })}
                        />
                    </div>
                    {emailAddressError && (
                        <>
                            <span className='form-control-feedback rioglyph rioglyph-error-sign' />
                            <span className='help-block'>
                                <FormattedMessage id='intl-msg:network.error.validEmailAddressRequired' />
                            </span>
                        </>
                    )}
                </div>
                <div className='form-group'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:network.invitationLink.inviteeName' />
                    </label>
                    <div className='input-group'>
                        <span className='input-group-addon'>
                            <span className='rioglyph rioglyph-user' />
                        </span>
                        <input
                            type='text'
                            className='form-control'
                            placeholder={intl.formatMessage({
                                id: 'intl-msg:network.placeholder.name',
                            })}
                            {...register('name', {
                                required: false,
                            })}
                        />
                    </div>
                </div>

                <div className='form-group'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:network.invitationLink.emailPreview' />
                    </label>
                    <div className='text-size-11 border border-style-dashed padding-5 pointer-events-none'>
                        {emailContent}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CreateStepTwo;
