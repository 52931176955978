import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';

import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { getSelectedPartner, partnerSelected } from '../../layout/appSlice';
import type { Partner } from '../../services/partnerNetwork.types';
import PartnerDetails from './PartnerDetails';
import { useDeletePartnerMutation } from '../../services/partnerNetworkApi';

const PartnerSidebar = () => {
    const dispatch = useAppDispatch();
    const selectedPartner = useAppSelector(getSelectedPartner);

    const [deletePartner] = useDeletePartnerMutation();

    const [isDeleteDialogShown, showDeleteDialog] = useState(false);

    const partnerIsDeleted = selectedPartner?.status === 'rescinded';

    const handleChange = (changedPartner: Partner) => {};
    const handleClose = () => dispatch(partnerSelected(undefined));

    const handleOpenDeleteConfirmation = () => {
        showDeleteDialog(true);
    };

    const handleDelete = async () => {
        if (selectedPartner !== undefined) {
            await deletePartner(selectedPartner);
        }
        showDeleteDialog(false);
        handleClose();
    };

    const deleteDialogButtons = (
        <div className='pull-right btn-toolbar'>
            <Button onClick={() => showDeleteDialog(false)}>
                <FormattedMessage id='intl-msg:network.label.close' />
            </Button>
            <Button onClick={handleDelete} className='btn-danger'>
                <span className='rioglyph rioglyph-trash' />
                <FormattedMessage id='intl-msg:network.label.deleteConfirmationConfirm' />
            </Button>
        </div>
    );

    return (
        <>
            <Sidebar
                title={<FormattedMessage id='intl-msg:network.label.partnerDetails' />}
                titleClassName='padding-left-10'
                closed={!selectedPartner}
                onClose={handleClose}
                footer={
                    <div
                        className={partnerIsDeleted ? 'btn-toolbar pull-right' : 'display-flex justify-content-between'}
                    >
                        {!partnerIsDeleted && (
                            <button
                                className='btn btn-danger btn-link'
                                type='button'
                                onClick={handleOpenDeleteConfirmation}
                            >
                                <span className='rioglyph rioglyph-trash' />
                                <span>
                                    <FormattedMessage id='intl-msg:network.label.deleteConfirmationConfirm' />
                                </span>
                            </button>
                        )}
                        <div className='btn-toolbar'>
                            <button className='btn btn-default' type='button' onClick={handleClose}>
                                <FormattedMessage id='intl-msg:network.label.close' />
                            </button>
                        </div>
                    </div>
                }
                resizable
                fly
                onBackdropClick={handleClose}
                enableFullscreenToggle
                position='right'
                minWidth={300}
                width={550}
            >
                <div className='padding-left-20 padding-right-20'>
                    {selectedPartner && <PartnerDetails partner={selectedPartner} onChange={handleChange} />}
                </div>
            </Sidebar>
            <Dialog
                show={isDeleteDialogShown}
                title={<FormattedMessage id='intl-msg:network.label.deleteConfirmationTitle' />}
                footer={deleteDialogButtons}
                onEsc={() => showDeleteDialog(false)}
                body={
                    <div>
                        <FormattedMessage id='intl-msg:network.label.deleteConfirmationContent' />
                    </div>
                }
            />
        </>
    );
};

export default PartnerSidebar;
