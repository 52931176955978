import { IntlProvider } from 'react-intl';
import { Routes, Route } from 'react-router-dom';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';

import { DEFAULT_LOCALE } from './../configuration/lang/lang';
import { useAppSelector } from './../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from './../configuration/lang/langSlice';
import { DEFAULT_ROUTE, INVITATION_ROUTE } from '../routes/routes';
import DefaultRedirect from '../routes/DefaultRedirect';
import PartnerInvitation from '../pages/PartnerInvitation';
import OverviewApp from './OverviewApp';

const App = () => {
    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);

    if (!(displayMessages && userLocale)) {
        return null;
    }

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <ApplicationLayout className={'PartnerNetwork'}>
                <Routes>
                    <Route path={DEFAULT_ROUTE} element={<OverviewApp />} />
                    <Route path={INVITATION_ROUTE} element={<PartnerInvitation />} />
                    <Route path='*' element={<DefaultRedirect />} />
                </Routes>
            </ApplicationLayout>
        </IntlProvider>
    );
};

export default App;
