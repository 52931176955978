import { FormattedMessage } from 'react-intl';
import EmptyState from '@rio-cloud/rio-uikit/EmptyState';

import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';
import { useAppDispatch } from '../../configuration/setup/hooks';
import { showInvitationDialog } from '../../layout/appSlice';

const EmptyPartnerList = () => {
    const dispatch = useAppDispatch();
    const handleCreatePartner = () => {
        gaPush({
            category: TRACKING_CATEGORIES.OVERVIEW,
            action: 'Add partner clicked',
            label: 'Add partner clicked',
        });
        dispatch(showInvitationDialog({ showDialog: true }));
    };
    return (
        <EmptyState
            outerClassName='border-none'
            headline={<FormattedMessage id='intl-msg:network.partnersTable.empty.title' />}
            message={<FormattedMessage id='intl-msg:network.partnersTable.empty.message' />}
            buttons={[
                {
                    text: (
                        <span>
                            <span className='rioglyph rioglyph-plus margin-right-5' />
                            <FormattedMessage id='intl-msg:network.label.addPartner' />
                        </span>
                    ),
                    onClick: handleCreatePartner,
                },
            ]}
        />
    );
};

export default EmptyPartnerList;
