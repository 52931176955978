import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../config';
import type { Account, ApiAccount } from './accounts.types';
import { mapAccount } from './accountsMapper';
import { prepareHeaders, handleResponse } from './api.helper';

export const accountsApi = createApi({
    reducerPath: 'accounts',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.ACCOUNTS_SERVICE,
        prepareHeaders,
    }),
    tagTypes: ['Account'],
    endpoints: builder => ({
        fetchAccount: builder.query<Account, string | undefined>({
            query: accountId => ({
                url: `/accounts/${accountId}`,
                responseHandler: async (response: Response) => handleResponse(response),
            }),
            transformResponse: (response: ApiAccount) => mapAccount(response),
            providesTags: ['Account'],
        }),
    }),
    refetchOnFocus: true,
});

export const { useFetchAccountQuery } = accountsApi;
