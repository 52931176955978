import { FormattedMessage, useIntl } from 'react-intl';
import head from 'lodash/fp/head';
import split from 'lodash/fp/split';
import getOr from 'lodash/fp/getOr';
import find from 'lodash/fp/find';
import CustomState from '@rio-cloud/rio-uikit/CustomState';

import type { AccountProfile, Invitation, PartnerConnectionTypeList } from '../../services/partnerNetwork.types';

type PendingInvitationStateProps = {
    invitation?: Invitation;
    partnerConnectionTypes?: PartnerConnectionTypeList;
    accountProfile?: AccountProfile;
    onClick: () => void;
};

const getLocaleShort = (locale: string): string => (head(split('-', locale)) || 'en').toLowerCase();
const getLocalizedFeatures = (connectionFeatures: { [key: string]: string[] } | undefined, locale: string): string[] =>
    getOr(
        getOr(['Feature 1', 'Feature 2', 'Feature 3'], 'en', connectionFeatures),
        getLocaleShort(locale),
        connectionFeatures
    );

const PendingInvitationState = (props: PendingInvitationStateProps) => {
    const { accountProfile, invitation, partnerConnectionTypes, onClick } = props;
    const intl = useIntl();

    const partnerConnectionType = find(
        partnerConnectionType => partnerConnectionType.connectionType === invitation?.connectionType,
        partnerConnectionTypes?.items
    );
    const displayName = partnerConnectionType?.displayName || 'Service';
    const connectionFeatures = partnerConnectionType?.connectionFeatures;
    const localizedFeatures = getLocalizedFeatures(connectionFeatures, intl.locale);
    return (
        <>
            <CustomState
                outerClassName='shadow-default'
                headline={
                    accountProfile?.displayName
                        ? intl.formatMessage(
                              {
                                  id: 'intl-msg:network.invitation.pendingInvitation.titleWithCompanyName',
                              },
                              {
                                  connectionType: displayName,
                                  companyName: accountProfile.displayName,
                              }
                          )
                        : intl.formatMessage(
                              {
                                  id: 'intl-msg:network.invitation.pendingInvitation.title',
                              },
                              {
                                  connectionType: displayName,
                              }
                          )
                }
                message={
                    <div>
                        <div className='text-medium margin-top-25'>
                            <FormattedMessage
                                id='intl-msg:network.invitation.pendingInvitation.message'
                                values={{
                                    connectionType: displayName,
                                }}
                            />
                        </div>
                        <div className='margin-top-25'>
                            <ul className='feature-list icon-list margin-x-10pct-ls margin-y-10 text-size-12 text-size-14-sm'>
                                {localizedFeatures.map(localizedFeature => (
                                    <li
                                        key={localizedFeature}
                                        className='padding-x-15 bg-lightest rounded rounded-large border-none display-flex align-items-center gap-2 text-left'
                                    >
                                        <span className='rioglyph rioglyph-ok text-color-primary' />
                                        <span className='text-color-darker'>{localizedFeature}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='margin-top-25'>
                            <FormattedMessage
                                id='intl-msg:network.invitation.pendingInvitation.sharedDataMessage'
                                values={{
                                    b: chunk => <span className='text-medium'>{chunk}</span>,
                                }}
                            />
                        </div>
                    </div>
                }
                icons={[
                    {
                        name: 'rioglyph rioglyph-send',
                        color: 'text-color-highlight',
                        className: 'text-size-300pct',
                    },
                ]}
                buttons={[
                    {
                        text: <FormattedMessage id='intl-msg:network.invitation.pendingInvitation.acceptInvitation' />,
                        onClick,
                    },
                ]}
            />
            <div className='display-flex justify-content-center max-width-100pct padding-top-25'>
                <div className='width-100pct max-width-600'>
                    <div className='text-right'>
                        <img src='https://cdn.rio.cloud/svg/common/ico_rio_powered.svg' alt='RIO Company Logo' />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PendingInvitationState;
