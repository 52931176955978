import type {
    AccountProfile,
    ApiAccountProfile,
    ApiEmailInvitation,
    ApiInvitation,
    ApiInvitationRedirect,
    ApiPartner,
    ApiPartnerConnectionType,
    ApiPartnerConnectionTypeList,
    ApiPartnerList,
    ConnectionType,
    EmailInvitation,
    Invitation,
    InvitationRedirect,
    Partner,
    PartnerConnectionType,
    PartnerConnectionTypeList,
    PartnerList,
} from './partnerNetwork.types';

export const mapAccountProfile = (apiAccountProfile: ApiAccountProfile): AccountProfile => {
    const { id, display_name } = apiAccountProfile;
    return {
        id,
        displayName: display_name,
    };
};

export const mapPartner = (apiPartner: ApiPartner): Partner => {
    const { id, name, status, connection_type, description, account_profile, connected_at, deleted_at } = apiPartner;
    return {
        id,
        name,
        status,
        connectionType: connection_type as ConnectionType,
        description,
        accountProfile: account_profile && mapAccountProfile(account_profile),
        partnerConnectionDisplayName: account_profile?.display_name,
        connectedAt: connected_at,
        deletedAt: deleted_at,
    };
};

export const mapPartnerList = (apiPartnerList: ApiPartnerList): PartnerList => {
    return {
        items: apiPartnerList.items.map(mapPartner),
    };
};

export const mapPartnerConnectionType = (apiPartnerConnectionType: ApiPartnerConnectionType): PartnerConnectionType => {
    const { connection_type, display_name, connection_features } = apiPartnerConnectionType;
    return {
        connectionType: connection_type as ConnectionType,
        displayName: display_name,
        connectionFeatures: connection_features,
    };
};

export const mapPartnerConnectionTypeList = (apiPartnerConnectionTypeList: ApiPartnerConnectionTypeList): PartnerConnectionTypeList => {
    return {
        items: apiPartnerConnectionTypeList.items.map(mapPartnerConnectionType),
    };
};

export const mapInvitation = (apiInvitation: ApiInvitation): Invitation => {
    const { id, partner_id, status, connection_type, redirect_uri } = apiInvitation;
    return {
        id,
        partnerId: partner_id,
        status,
        connectionType: connection_type as ConnectionType,
        redirectUri: redirect_uri,
    };
};

export const mapPartnerToApi = (partner: Partner): ApiPartner => {
    const { id, name, status, connectionType, description } = partner; // Account profile not included here as it is a read only property
    return {
        id,
        name,
        status,
        connection_type: connectionType,
        description,
    };
};
export const mapInvitationToApi = (invitation: Invitation): ApiInvitation => {
    const { id, partnerId, status, connectionType, redirectUri } = invitation;
    return {
        id,
        partner_id: partnerId,
        status,
        connection_type: connectionType,
        redirect_uri: redirectUri,
    };
};

export const mapInvitationRedirectToApi = (invitationRedirect: InvitationRedirect): ApiInvitationRedirect => {
    const { redirectUri } = invitationRedirect;
    return {
        redirect_uri: redirectUri,
    };
};
export const mapEmailInvitationToApi = (emailInvitation: EmailInvitation): ApiEmailInvitation => {
    const { partnerId, invitationId, emailAddress, inviteeName, language } = emailInvitation;
    return {
        partner_id: partnerId,
        invitation_id: invitationId,
        email_address: emailAddress,
        invitee_name: inviteeName,
        language: language,
    };
};
