import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';

import type { Partner } from '../services/partnerNetwork.types';

type PartnershipStatusProps = {
    partner: Partner;
};

const getLabelClassForStatus = (status: string) => {
    if (status === 'connected') {
        return 'label-success';
    }
    if (status === 'expired') {
        return 'label-danger';
    }
    return 'label-default';
};

const PartnershipStatus = (props: PartnershipStatusProps) => {
    const intl = useIntl();

    const dateFormatter: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    const displayDate = () => {
        const date = partner.status === 'rescinded' ? partner.deletedAt : partner.connectedAt;
        return date && intl.formatDate(new Date(date), dateFormatter);
    };

    const { partner } = props;
    const labelClass = getLabelClassForStatus(partner.status);
    return (
        <div className='space-x-5'>
            <span className={`label label-condensed ${labelClass}`}>
                <FormattedMessage id={`intl-msg:network.status.${partner.status}`} />
                {partner.status === 'connected' && <span className='rioglyph rioglyph-ok padding-left-5' />}
            </span>
            <label>{displayDate()}</label>
        </div>
    );
};

export default PartnershipStatus;
