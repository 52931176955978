import { useState } from 'react';
import { type IntlShape, useIntl } from 'react-intl';
import { identity } from 'io-ts';
import get from 'lodash/fp/get';
import { SortDirection } from '@rio-cloud/rio-uikit';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import { sortByProperty, type SortDirectionType } from '@rio-cloud/rio-uikit/SortUtils';

import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import EmptyPartner from './EmptyPartner';
import NotFoundPartner from './NotFoundPartner';
import type { Partner } from '../../services/partnerNetwork.types';
import { getSelectedPartnerId, partnerSelected } from '../../layout/appSlice';
import PartnershipStatus from '../../components/PartnershipStatus';
import Counts from '../../components/Counts';
import ConnectionTypeFormatter from '../../components/ConnectionTypeFormatter';
import { getTrackingAttributes, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';
import { featureToggles } from '../../configuration/setup/featureToggles';
import { dataSharingAgreements } from '../../data/demoData';

type PartnerTableProps = {
    partners: Partner[];
    isEmptyList: boolean;
    isNotFoundList: boolean;
};

const tableClasses = `table table-bordered table-layout-fixed table-column-overflow-hidden 
    table-hover table-sticky table-head-filled`;

const makeColumns = (intl: IntlShape) =>
    [
        {
            title: intl.formatMessage({ id: 'intl-msg:network.label.companyName' }),
            field: 'name',
            styles: { minWidth: '100px' },
        },
        {
            title: intl.formatMessage({ id: 'intl-msg:network.label.account' }),
            field: 'partnerConnectionDisplayName',
            styles: { minWidth: '100px' },
        },
        {
            title: intl.formatMessage({ id: 'intl-msg:network.label.description' }),
            field: 'description',
            styles: { minWidth: '100px' },
        },
        {
            title: intl.formatMessage({ id: 'intl-msg:network.label.status' }),
            field: 'status',
            styles: { minWidth: '100px' },
            renderer: (partner: Partner) => <PartnershipStatus partner={partner} />,
        },
        {
            title: intl.formatMessage({ id: 'intl-msg:network.label.connectionType' }),
            field: 'connectionType',
            styles: { minWidth: '100px' },
            renderer: (partner: Partner) => <ConnectionTypeFormatter connectionType={partner.connectionType}
                                                                     showIcon />,
        },
        featureToggles.poc && {
            title: intl.formatMessage({ id: 'intl-msg:network.label.agreements' }),
            field: 'partnerAgreements',
            styles: { minWidth: '100px' },
            renderer: (partner: Partner) =>
                partner.status !== 'pending' && <Counts count={dataSharingAgreements.length} requestsCount={1} />,
        },
    ].filter(identity);

const PartnerTable = (props: PartnerTableProps) => {
    const { partners, isEmptyList, isNotFoundList } = props;

    const intl = useIntl();
    const dispatch = useAppDispatch();
    const selectedPartnerId = useAppSelector(getSelectedPartnerId);

    const [sortBy, setSortBy] = useState('name');
    const [sortDir, setSortDir] = useState<SortDirectionType>(SortDirection.ASCENDING);

    const getSortDir = (sortDirection: SortDirectionType, sortColumnsBy: string, previousSortBy: string) => {
        if (sortColumnsBy === previousSortBy) {
            return sortDirection === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
        }
        return SortDirection.ASCENDING;
    };

    const handleSortChange = (event: React.MouseEvent<HTMLElement>) => {
        const newSortBy = event.currentTarget.getAttribute('data-sortby');
        if (newSortBy) {
            handleCardSortChange(newSortBy, getSortDir(sortDir, newSortBy, sortBy));
        }
    };

    const handleCardSortChange = (newSortBy: string, newSortDir: SortDirectionType) => {
        setSortBy(newSortBy);
        setSortDir(newSortDir);
    };

    const handleSelectPartner = (partner: Partner) => dispatch(partnerSelected(partner.id));

    const columns = makeColumns(intl);

    const emptyFeedback = (isEmptyList || isNotFoundList) && (
        <tr>
            <td colSpan={columns.length} className='shadow-none'>
                {isEmptyList && <EmptyPartner />}
                {isNotFoundList && <NotFoundPartner />}
            </td>
        </tr>
    );

    const renderTableHead = (column: string, label: string, sortByColumn: string, sortDirection: SortDirectionType) => {

        return (
            <th
                key={column}
                className='user-select-none sort-column'
                onClick={handleSortChange}
                data-field={column}
                data-sortby={column}
                title={label}
            >
                <span>
                    {sortByColumn === column ? <SortArrows direction={sortDirection} /> : <SortArrows />}
                    <span>{label}</span>
                </span>
            </th>
        );
    };

    const sortedPartners = sortBy ? sortByProperty(partners, sortBy as keyof Partner, sortDir) : partners;

    return (
        <div className=''>
            <table className={tableClasses}>
                <colgroup>
                    {columns.map(column => (
                        <col key={column.field} style={column.styles} />
                    ))}
                </colgroup>
                <thead
                    {...getTrackingAttributes({
                        trigger: 'click',
                        category: TRACKING_CATEGORIES.OVERVIEW,
                        action: 'Table header clicked',
                        label: 'Table header clicked',
                    })}
                >
                <tr>
                    {columns.map(column => renderTableHead(column.field, column.title, sortBy, sortDir))}
                </tr>
                </thead>
                <tbody>
                {emptyFeedback}
                {sortedPartners.map(partner => {
                    const { id } = partner;
                    const isSelected = selectedPartnerId === id;
                    return (
                        <tr
                            key={id}
                            className={`cursor-pointer ${isSelected ? 'active' : ''}`}
                            onClick={() => handleSelectPartner(partner)}
                            {...getTrackingAttributes({
                                trigger: 'click',
                                category: TRACKING_CATEGORIES.OVERVIEW,
                                action: 'Table row clicked',
                                label: 'Table row clicked',
                            })}
                        >
                            {columns.map(column => (
                                <td key={column.field} data-field={column.field}>
                                        <span>
                                            {column.renderer ? column.renderer(partner) : get(column.field, partner)}
                                        </span>
                                </td>
                            ))}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};

export default PartnerTable;
