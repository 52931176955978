import * as t from 'io-ts';

export type AccountProfile = {
    id: string;
    displayName: string;
};

export type Partner = {
    id: string;
    name: string;
    description?: string;
    status: string;
    connectionType: ConnectionType;
    accountProfile?: AccountProfile;
    partnerConnectionDisplayName?: string;
    connectedAt?: string;
    deletedAt?: string;
};

export type PartnerList = {
    items: Partner[];
};

export type PartnerConnectionType = {
    connectionType: ConnectionType;
    displayName: string;
    connectionFeatures: {
        [key: string]: string[]
    }
};

export type PartnerConnectionTypeList = {
    items: PartnerConnectionType[];
};

export const ConnectionTypes = {
    ORDER_EXCHANGE: 'order-exchange',
    RENTAL: 'rental',
} as const;

export type ConnectionType = (typeof ConnectionTypes)[keyof typeof ConnectionTypes];

export type Invitation = {
    id: string;
    partnerId: string;
    status: string;
    connectionType: ConnectionType;
    redirectUri?: string;
};

export type InvitationRedirect = {
    redirectUri: string;
};

export type InvitationQuery = {
    id: string | undefined;
    partnerId: string | undefined;
};

export type InvitationRequest = {
    partnerId: string;
    invitationRedirectUri: string; 
};

export type EmailInvitation = {
    partnerId: string;
    invitationId: string;
    emailAddress: string;
    inviteeName: string;
    language: string;
};

const AccountProfileCodec = t.type({
    id: t.string,
    display_name: t.string,
});

const PartnerCodec = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        status: t.string,
        connection_type: t.string,
    }),
    t.partial({
        description: t.string,
        account_profile: AccountProfileCodec,
        connected_at: t.string,
        deleted_at: t.string,
    }),
]);

export const PartnerListCodec = t.type({
    items: t.array(PartnerCodec),
});

const PartnerConnectionTypeCodec = t.type({
    connection_type: t.string,
    display_name: t.string,
    connection_features: t.record(t.string, t.array(t.string)),
});

export const PartnerConnectionTypeListCodec =t.type({
    items: t.array(PartnerConnectionTypeCodec),
});


export const InvitationCodec = t.intersection([
    t.type({
        id: t.string,
        partner_id: t.string,
        status: t.string,
        connection_type: t.string,
    }),
    t.partial({
        redirect_uri: t.string,
    }),
]);

export const InvitationRedirectCodec = t.intersection([
    t.type({
        redirect_uri: t.string,
    }),
    t.partial({ }),
]);

export const EmailInvitationCodec = t.intersection([
    t.type({
        partner_id: t.string,
        invitation_id: t.string,
        email_address: t.string,
        invitee_name: t.string,
        language: t.string,
    }),
    t.partial({}),
]);

export type ApiAccountProfile = t.TypeOf<typeof AccountProfileCodec>;
export type ApiPartnerConnectionType = t.TypeOf<typeof PartnerConnectionTypeCodec>;
export type ApiPartnerConnectionTypeList = t.TypeOf<typeof PartnerConnectionTypeListCodec>;
export type ApiPartner = t.TypeOf<typeof PartnerCodec>;
export type ApiPartnerList = t.TypeOf<typeof PartnerListCodec>;
export type ApiInvitation = t.TypeOf<typeof InvitationCodec>;
export type ApiInvitationRedirect = t.TypeOf<typeof InvitationRedirectCodec>;
export type ApiEmailInvitation = t.TypeOf<typeof EmailInvitationCodec>;
